@media only screen and (max-width: 500px) {
    .headerimage {
        width:100%;
    }
    .liquor-text {
        margin-top:30px;
    }
    .label-font {
        font-size:12px;
    }
    .category-group {
        margin-bottom: 15px;
        border-bottom: 1px solid lightgray;
    }
}

.info-text {
    font-size: 18px;

}

.top-space {
    margin-top:25px;
}
.top-space-small {
    margin-top:15px;
}

.image-container {
    display: flex;
    justify-content: center;
    background-color: #2F2F2F;
    /* flex-direction: column; */
}

.headerimage {
    width: 65%;
}